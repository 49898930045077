export default {
  Admin: [
    { action: 'read', subject: 'Users' },
    { action: 'read', subject: 'Contacts' },
    { action: 'read', subject: 'Headquarters' },
    { action: 'read', subject: 'Departments' },
    { action: 'read', subject: 'Meetingrooms' },
    { action: 'read', subject: 'Vehicles' },
    // { action: 'read', subject: 'Holidayproposals' },
    { action: 'read', subject: 'Justificationproposals' },
    { action: 'read', subject: 'Calendar' },
    { action: 'read', subject: 'Calendarusers' },
    { action: 'read', subject: 'Worktimes' },
    { action: 'read', subject: 'Conversations' },
    { action: 'read', subject: 'Justifications' },
    { action: 'read', subject: 'Expensenotes' },
    { action: 'read', subject: 'Businesstrips' },
    { action: 'read', subject: 'Bookmarks' },
    { action: 'read', subject: 'Auth' },
  ],
  Reception: [
    { action: 'read', subject: 'MeetingroomsReception' },
    { action: 'read', subject: 'VehiclesReception' },
    { action: 'read', subject: 'UniformsReception' },
    { action: 'read', subject: 'CalendarReception' },
    { action: 'read', subject: 'ConversationsReception' },
    { action: 'read', subject: 'Auth' },
  ],
  Contabilità: [
    { action: 'read', subject: 'Justificationproposals' },
    { action: 'read', subject: 'Justifications' },
    { action: 'read', subject: 'Expensenotes' },
    { action: 'read', subject: 'Businesstrips' },
    { action: 'read', subject: 'Auth' },
  ],
  Approvatore: [
    { action: 'read', subject: 'ManagerJustifications' },
    { action: 'read', subject: 'ManagerCalendar' },
    { action: 'read', subject: 'ManagerJustificationproposals' },
    { action: 'read', subject: 'ManagerJustificationsSchedule' },
    { action: 'read', subject: 'ManagerUniforms' },
    { action: 'read', subject: 'Auth' },
  ],
  Hr: [
    { action: 'read', subject: 'ManagerJustifications' },
    { action: 'read', subject: 'ManagerCalendar' },
    { action: 'read', subject: 'ManagerJustificationproposals' },
    { action: 'read', subject: 'ManagerJustificationsSchedule' },
    { action: 'read', subject: 'Auth' },
  ],
  user: [],
}
